import React, { ReactNode, useMemo } from 'react'
import { ColumnTable, PaginationType } from './types/TableTypes'
import { Col, Row, Table as BaseTable } from 'reactstrap'
import { TableBody } from './TableBody'
import { TableHead } from './TableHead'
import { TablePagination } from './TablePagination'
import { Icon } from '../Icon'
import { useMobile } from '../../hooks'

type LoadingText = { title: string; subtitle: string }

export type TableProps<T> = {
  /**
   * Array con las columnas que contendrá la tabla,
   * Se debe agregar el columnName que corresponde al key de data que se renderizará en esta columna
   * también se puede formatear con la función cellFormat que entrega la siguiente información
   * (row, value, index, data)
   */
  columns: ColumnTable<T>[]
  /**
   * Array con la información que se quiere renderizar en cada fila de la tabla
   */
  data: T[]
  /**
   * Información para agregar paginación bajo la tabla
   */
  pagination?: PaginationType
  /**
   * Texto o componente que se mostrará cuando el array data esté vacío
   * NOTA: La paginación se mostrará SOLO si existe más de una página de resultados
   */
  noResultsText?: string | React.ReactNode
  /**
   * Indica si las filas de la tabla se verán de colores alternados (blanco/gris). Por defecto es true.
   * Nota: Si el color de la tabla es gray el valor striped no tendrá efecto.
   */
  striped?: boolean
  /**
   * Fila adicional bajo el header, puede ser utilizada para agregar buscador o alguna información adicional
   */
  subHeader?: JSX.Element
  /**
   * Altura máxima en pixeles que tendrá la tabla, se agrega scroll lateral dejando el header fijo
   */
  height?: number
  /**
   * Indica cuando se están recuperando nuevos resultados.
   */
  isLoadingResults?: boolean
  /**
   * Componente que se desplegará cuando se están cargando los resultados.
   */
  loadingView?: LoadingText
  /**
   * Muestra la tabla con header color secondary o gris (cuando es gris las filas también serán un tono más claro de gris)
   */
  color?: 'gray' | 'default' | 'transparent'
  /**
   * Para indicar el alto de las filas de la tabla
   */
  size?: 'small' | 'normal'
  /**
   * Indica si las filas quedan sin borde entre ellas.
   */
  borderless?: boolean

  noDefaultHead?: boolean
  headComponent?: (options: {
    columns: ColumnTable<T>[]
    data: T[]
  }) => ReactNode | ReactNode[]
  noDefaultFoot?: boolean
  footComponent?: (options: {
    columns: ColumnTable<T>[]
    data: T[]
  }) => ReactNode | ReactNode[]
  notResponsive?: boolean
}

/**
 * Componente tabla utilizado para renderizar un listado de resultados.
 * Se le puede agregar paginador agregando el prop "pagination"<br/>
 * Si sólo deseas utilizar los estilos de la tabla, puedes utilizar las tablas de reactstrap
 * https://reactstrap.github.io/components/tables/ y utilizar la tabla con el className="g-table"
 * y las propiedades "striped" y "borderless"
 *
 */
export function Table<T>({
  columns: columnsProp,
  data,
  pagination,
  noResultsText = 'Sin resultados.',
  striped = true,
  subHeader,
  height,
  isLoadingResults = false,
  loadingView = {
    title: 'Espera un momento',
    subtitle: 'Los resultados se están cargando.'
  },
  color = 'default',
  size = 'normal',
  borderless = true,
  notResponsive = false,
  noDefaultHead = false,
  headComponent,
  noDefaultFoot = false,
  footComponent
}: TableProps<T>) {
  const isMobile = useMobile()

  const columns = useMemo(() => {
    return columnsProp.filter((col) => {
      return !(isMobile && col.hideOnMobile)
    })
  }, [columnsProp, isMobile])

  const noResults = (
    <tfoot>
      <tr>
        <td colSpan={columns.length} className='text-center py-4'>
          {typeof noResultsText === 'string' ? (
            <span>{noResultsText}</span>
          ) : (
            noResultsText
          )}
        </td>
      </tr>
    </tfoot>
  )

  return (
    <React.Fragment>
      <div
        className={`g-table-${color} g-table-${size} ${
          height ? 'fixed-header' : ''
        }`}
        style={{ maxHeight: `${height ? height + 'px' : 'auto'}` }}
      >
        <BaseTable
          className='g-table'
          striped={striped}
          borderless={borderless}
          responsive={notResponsive === true ? false : color !== 'transparent'}
        >
          {!noDefaultHead && (
            <TableHead columns={columns} subHeader={subHeader} />
          )}
          {headComponent ? (
            <thead>{headComponent({ columns, data })}</thead>
          ) : null}
          {!isLoadingResults && <TableBody columns={columns} data={data} />}
          {/* MENSAJE INDICANDO QUE NO HAY RESULTADOS */}
          {!noDefaultFoot && data.length === 0 && noResults}
          {footComponent ? (
            <tfoot>{footComponent({ columns, data })}</tfoot>
          ) : null}
        </BaseTable>
      </div>

      {/* PAGINADOR */}
      {!isLoadingResults &&
        data.length > 0 &&
        pagination &&
        pagination.totalPages > 1 && (
          <TablePagination pagination={pagination} />
        )}

      {/* COMPONENTE QUE INDICA QUE SE ESTÁN CARGANDO LOS RESULTADOS */}
      {isLoadingResults && (
        <Row className='text-center py-3'>
          <Col xs={12}>
            <Icon className='spin' name='circle' size={40} color='secondary' />
          </Col>
          <Col xs={12} className='pt-4 pb-3 fs-22 fw-400 text-dark'>
            {loadingView.title}
          </Col>
          <Col xs={12} className='fs-16'>
            {loadingView.subtitle}
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

Table.defaultProps = {
  noResultsText: 'Sin resultados.',
  isLoadingResults: false,
  loadingView: {
    title: 'Espera un momento',
    subtitle: 'Los resultados se están cargando.'
  },
  color: 'default',
  size: 'normal',
  borderless: true
}
